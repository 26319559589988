<template>
  <div class="father">
    <!-- 荣誉图片 -->
    <div
      v-show="shows == 1"
      class="out-head"
      id="honorPics"
      @mouseenter="clear()"
      @mouseleave="leaveTime()"
    >
      <div class="loading" v-if="load">
        <span>拼命加载中.....</span>
      </div>
      <div class="item" v-else>
        <div v-if="honoraryPicsList.length == 0" class="noPics">
          <span>暂无图片.....</span>
        </div>
        <div class="work-img-header" v-viewer>
          <img
            v-for="(item, index) in honoraryPicsList"
            :key="index"
            v-lazy="baseUrl + item"
            :class="isLarge ? 'work-img2' : 'work-img'"
          />
        </div>
      </div>
    </div>
    <!-- 精彩视频 -->
    <div class="out-head2" v-if="shows == 2">
      <div class="noPics2">
        <video :src="baseUrl + videoUrl" controls autoplay muted loop></video>
      </div>
    </div>
    <!-- 精彩图片 -->
    <div v-show="shows == 3" class="out-head3"  id="perfectPics"
      @mouseenter="perfectClear()"
      @mouseleave="perfectLeaveTime()">
      <div class="perfectImgbox">
        <div
          class="perfectItem"
          v-viewer
          v-for="(item, index) in perfectPicsListL"
          :key="index"
        >
          <img v-if="item.url" v-lazy="baseUrl + item.url" />
          <div v-if="item.title" class="perfectItemTitle">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HonoraryPics",
  props: ["isLarge"],
  data() {
    return {
      isChoice: false, //用来判断是否重新选择子菜单的项目 如果选择了，图片应该从头开始往下滑动
      honoraryPicsList: [], //荣誉图片
      perfectPicsListL: [], //精彩图片
      videoUrl: "", //精彩视频
      shows: -1,
      fileSrc: process.env.VUE_APP_BASE_API + "/",
      load: true,
      time: null, //定时器
      height: -1, //计算滑动高度
      baseUrl: "http://hunanqunyinghui.oss-cn-guangzhou.aliyuncs.com/",
    };
  },
  created() {
    // this.getData();
  },
  mounted() {
    if (this.honoraryPicsList.length > 0) {
      this.autoSroll("honorPics");
    }else if(this.perfectPicsListL.length > 0){
      this.autoSroll("perfectPics");
    }
  },
  destroyed() {
    clearInterval(this.time);
    this.time = null;
  },
  watch: {
    isChoice() {
      if(this.time){
        clearInterval(this.time)
        this.timer = null
      }
      //1-荣誉图片 2-精彩视频 3-精彩图片
      if (this.shows == 1) {
        //荣誉图片
        this.$nextTick(() => {
          this.height = 0;
          document.getElementById("honorPics").scrollTop = this.height + 1;
        });
      }else if(this.shows == 3){
        this.$nextTick(() => {
          this.height = 0;
          document.getElementById("perfectPics").scrollTop = this.height + 1;
        });
      }

    },
    honoraryPicsList(newValue) {
      if (newValue.length > 0) {
        this.autoSroll("honorPics");
      }
    },
    perfectPicsListL(newValue){
      if(newValue.length>0){
        this.autoSroll("perfectPics");
      }
    }
  },
  methods: {
    autoSroll(Id) {
      this.time = setInterval(function () {
        //获取当前滚动条高度
        var current = document.getElementById(Id).scrollTop;
        if (current == this.height) {
          //滚动到底端,返回顶端
          this.height = 0;
          document.getElementById(Id).scrollTop = this.height + 1;
        } else {
          //以25ms/3.5px的速度滚动
          this.height = current;
          document.getElementById(Id).scrollTop = this.height + 1;
        }  
      }, 50);
    },
    clear() {
      clearInterval(this.time);
      this.time = null;
    },
    leaveTime() {
      this.autoSroll("honorPics");
    },
    //精彩图片
    perfectClear(){
      clearInterval(this.time);
      this.time = null;
    },
    perfectLeaveTime(){
      this.autoSroll("perfectPics");
    }
  },
};
</script>

<style lang="less" scoped>
.father {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .out-head {
    width: 100%;
    height: 92%;
    margin-top: 6%;
    //padding: 0 0 10px 0;
    overflow-y: scroll;
    //加载中
    .loading {
      font-size: 18px;
      color: #fff;
      display: flex;
      width: 100%;
      height: 80%;
      justify-content: center;
      align-items: center;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }

    .item {
      width: 100%;
      height: auto;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow: hidden;

      //暂无图片
      .noPics {
        font-size: 18px;
        color: #fff;
        height: 100%;
        margin: 30% auto;
        font-family: "华文细黑", Courier New, Courier, monospace;
      }

      .work-title {
        padding: 3px 8px;
        width: auto;
        min-width: 100px;
        text-align: center;
        height: 18%;
        background-color: #0e3e9d;
        color: rgb(167, 198, 235);
        margin: 0 auto;
        font-size: 16px;
        font-family: "华文细黑", Courier New, Courier, monospace;
        margin-bottom: 3%;
      }

      .work-img-header {
        width: 100%;
        margin: 0 auto;
        height: 80%;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
      }

      .work-img {
        width: 90%;
        height: 150px;
        margin-bottom: 18px;
        margin-left: 15px;
        object-fit: cover;
      }
      .work-img2 {
        width: 46%;
        height: 170px;
        margin-bottom: 18px;
        margin-left: 15px;
        object-fit: cover;
      }
    }

    //.father::-webkit-scrollbar {
    //  display: none;
    //}
  }
  .out-head2 {
    width: 100%;
    height: 100%;
    margin-top: 6%;
    .noPics2 {
      height: 100%;
      width: 97%;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  //精彩图片
  .out-head3 {
    width: 100%;
    height: 100%;
    margin-top: 6%;
    overflow-y: scroll;
    .perfectItem{
      width: 92%;
      margin: 0 auto;
      margin-bottom: 15px;
      img{
        width: 100%;
        height: 150px;
        object-fit: cover;
      }
      .perfectItemTitle{
        margin-top: 5px;
        color:#b3ebf3;
        font-size: 17px;
        text-align: center;
      }
      // border: 1px solid red;
    }
  }
}
//.father::-webkit-scrollbar {
//  display: none;

// 滚动条样式
.out-head::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.out-head::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #5689ee;
}
.out-head::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #151456;
}
.out-head3::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.out-head3::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #5689ee;
}
.out-head3::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #151456;
}
</style>